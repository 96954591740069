import * as EmailValidator from 'email-validator'

const DATE_FORMAT = 'yyyy-MM-dd'

function validateEmail(email) {
  if (email.length === 0) {
    return 'This field is required.'
  } else if (!EmailValidator.validate(email)) {
    return 'Email address is invalid.'
  }
  return
}

function validateTextField(value) {
  if (!value || value.length === 0) return 'This field is required.'
  return
}

function validateDateRange(start, end) {
  if (end < start) return 'Start date must be less than or equal to end date.'
  return
}

function processRelativeFreq(data) {
  const aggData = []
  let total = 0

  for (let i = 0; i < 15; i++) {
    aggData.push({
      'speed': `< ${i + 1}`,
      'val': 0
    })
  }

  for (let i = 0; i < data.length; i++) {
    total++
    if (data[i]['WS_80 [m/s]']) {
      const p = Math.floor(data[i]['WS_80 [m/s]'])
      if (p < 15) aggData[p]['val']++
    }
  }

  for (let i = 0; i < 15; i++) {
    aggData[i]['val'] = (aggData[i]['val'] / total) * 100
  }

  return aggData
}

function processDirectionAvg(data) {
  const ser = []
  const limits = [[0, 3], [3, 6], [6, 9], [9, 12], [12, 15], [15, 1 / 0.0]]
  for (let n = 0; n < limits.length; n++) {
    let total = 0
    const datax = []
    const countx = []

    // init datax
    for (let i = 0; i < 12; i++) {
      datax.push([i, 0])
      countx.push(0)
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i]['WS_80 [m/s]']) {
        total++
        const dir = (parseInt(data[i]['WD_80 [deg]']) + 360) % 360
        const ws = parseInt(data[i]['WS_80 [m/s]'])
        const p = Math.floor(dir / 30)
        if (ws > limits[n][0] && ws < limits[n][1]) {
          countx[p]++
          datax[p][1] += ws
        }
      }
    }

    for (let i = 0; i < 12; i++) {
      datax[i][1] = (countx[i] / total) * 100
    }
    ser.push({ label: limits[n], data: datax })
  }

  const final = []
  const dirs = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330]
  dirs.forEach((v, i) => {
    final.push({
      category: `${v}º`,
      '0-3': ser[0]['data'][i][1],
      '3-6': ser[1]['data'][i][1],
      '6-9': ser[2]['data'][i][1],
      '9-12': ser[3]['data'][i][1],
      '12-15': ser[4]['data'][i][1],
      '15+': ser[5]['data'][i][1],
    })
  })

  return final
}

function processShear(data) {
  const ser = [];
  const heights = [];
  const counts = [];
  for (var h = 50; h <= 200; h += 10) {
    const height = `WS_${h} [m/s]`
    ser.push({
      'speed': 0,
      'height': h
    })
    heights.push(height);
    counts.push(0);
  }

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < heights.length; j++) {
      const ws = parseFloat(data[i][heights[j]])
      if (ws) {
        ser[j]['speed'] += ws
        counts[j]++
      }
    }
  }

  for (let i = 0; i < heights.length; i++) {
    ser[i]['speed'] = ser[i]['speed'] / counts[i];
  }

  return ser
}

function processAvailability(data) {
  const ser = [];
  const heights = [];
  const counts = [];
  for (let h = 50; h <= 200; h += 10) {
    const height = `WS_${h} [m/s]`
    ser.push({
      'val': 0,
      'height': h,
    });
    heights.push(height);
    counts.push(0);
  }

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < heights.length; j++) {
      const ws = parseFloat(data[i][heights[j]])
      if (ws) {
        ser[j]['val']++
      }
      counts[j]++
    }
  }

  for (let i = 0; i < heights.length; i++) {
    ser[i]['val'] = (ser[i]['val'] / counts[i]) * 100;
  }

  return ser
}

export {
  validateEmail,
  validateTextField,
  validateDateRange,
  processRelativeFreq,
  processDirectionAvg,
  processShear,
  processAvailability,
  DATE_FORMAT,
}
