import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
// material
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  const activeBgColor = theme.palette.secondary.main
  return {
    button: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: 'white'
      },
      '&:focus': {
        backgroundColor: theme.palette.action.hover,
        color: 'white'
      },
      marginRight: theme.spacing(1),
    },
    active: {
      backgroundColor: activeBgColor,
      color: 'white',
      marginRight: theme.spacing(1),
      '&:hover': {
        backgroundColor: activeBgColor,
        color: 'white'
      },
      '&:focus': {
        backgroundColor: activeBgColor,
        color: 'white'
      },
    },
  }
})

function NavButton(props) {
  const classes = useStyles()
  const {
    to,
    name,
    handleClick,
    buttonProps,
    textStyle,
    textVariant,
    textColor,
    isActive,
  } = props
  const buttonClass = isActive ? classes.active : classes.button
  return (
    <Button
      // disableRipple
      disableFocusRipple
      disableTouchRipple
      disableElevation
      component={NavLink}
      to={to}
      name={name}
      onClick={handleClick}
      className={buttonClass}
      variant='text'
      {...buttonProps}
    >
      <Typography variant={textVariant} style={textStyle} color={textColor}>
        {name}
      </Typography>
    </Button>
  )
}

NavButton.propTypes = {
  to: PropTypes.string,
  handleClick: PropTypes.func,
  name: PropTypes.string,
  isActive: PropTypes.bool,
}

export default NavButton
